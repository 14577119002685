import { message } from "ant-design-vue";
import { md5 } from "js-md5";
import storage from "../storage";
function _sign(param, t) {
  return md5(param + t + "U7KK2GV5GH68");
}
const AllUrl = {
  api5: ["https://dev-t5.techfx88.com/", "https://t5.techfx88.com/"],
  cms: ["https://dev-cms2.techfx88.com/", "https://cms2.techfx88.com/"],
  apitrade: ["https://dev1-apitrade.techfx88.com/", "https://apitrade.techfx88.com/"],
  apiholding: ["https://dev2-www.techfx88.com/", "https://w1.techfx88.com/"],
  apistock: ["https://dev-apistock.techfx88.com/", "https://apistock.techfx88.com/"],
  rms: ["https://rms.techfx88.com/", "https://rms.techfx88.com/"],
  w1: ["https://w1.techfx88.com/", "https://dev2-www.techfx88.com/"],
  pwd: ["https://dev1-apitrade.techfx88.com","https://apitrade.techfx88.com"],
};
let isNavigateTo = false;

function cleanStorage() {
  // message.error("你的登录状态已过期，请重新登录");
  // storage.setHasLogin(false);
  // storage.setToken("");
  // storage.setUserInfo({});
  // storage.setUserList({});
  // if (!isNavigateTo) {
  // 	isNavigateTo = true
  // }
}

const fetch = async (url, options, type) => {
  const host = useRequestURL().host;
  const isTest = /(dev\-|^localhost(?::\d+)?$|127\.0\.0\.1|172)/.test(host);
  const token = storage.getToken();
  const locale = useCookie("i18n_redirected");
  if (!isString(url)) {
    console.warn("url is must be string!");
    return;
  }
  const reqUrl = (isTest ? AllUrl[type][0] : AllUrl[type][1]) + url;
  if (!options.headers) {
    options.headers = {
      "Accept-Language":
        locale.value === "hk" ? "zh-Hant" : locale.value === "en" ? "en" : "zh-Hans",
      "User-Agent": "web",
      Agent: "web",
      version: "9.99.9",
    };
  }
  if (options.customContentType) {
     options.headers["Content-Type"] = options.customContentType;
  }
  if (options.params && token) {
    options.params.token = token;
  }
  if (options.body && token && !(url.indexOf("contact_us") != -1)) {
    options.body.token = token;
  }
  if (type === "apitrade" || type === "apiholding") {
    let _t = (Date.parse(new Date()) / 1000).toString();
    if (options.params) {
      options.params._t = _t;
      if (url.indexOf("login") != -1 || url.indexOf("contact_us") != -1) {
        options.params._sign = _sign(
          options.params.mobile || options.params.phone,
          _t,
        );
      } else {
        if (token) {
          options.params._sign = _sign(token, _t);
        }
      }
    }
    if (options.body) {
      options.body._t = _t;
      if (url.indexOf("login") != -1 || url.indexOf("contact_us") != -1) {
        options.body._sign = _sign(
          options.body.mobile || options.body.phone,
          _t,
        );
      } else {
        if (token) {
          options.body._sign = _sign(token, _t);
        }
      }
    }
  }
  return new Promise((resolve, reject) => {
    useFetch(reqUrl, { ...options, server: false })
      .then(({ data, error }) => {
        isNavigateTo = false;
        if (error.value) {
          message.error("网络错误");
          reject(error.value);
          return;
        }
        const res = data.value;
        if (res.status == 1001 || res.status == 1002) {
          cleanStorage();
        }
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default new (class Http {
  get(url, params, type) {
    return fetch(url, { method: "GET", params }, type);
  }
  post(url, body, type) {
    if (body.customContentType) {
      return fetch(url, { method: "POST", body: body.data, customContentType: body.customContentType}, type);
    } else {
      return fetch(url, { method: "POST", body }, type);
    }

  }
  reqData(url, body) {
    return fetch(url, { method: "POST", body, headers: {} });
  }
})();
