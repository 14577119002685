import Http from "../assets/api/index.js";
import Qs from 'qs';

const api5Get = (url, data) => {
  return Http.get(url, data, "api5");
};
const rmsGet = (url, data) => {
  return Http.get(url, data, "rms");
};
const w1Post = (url, data) => {
  return Http.post(url, data, "w1");
};

const resetPwd = (url, data) => {
  return Http.get(url, data, "pwd");
};
const resetPwdPost = (url, data) => {
  return Http.post(url, data, "pwd");
};
const api5Post = (url, data) => {
  return Http.post(url, data, "api5");
};
const cmsPost = (url, data) => {
  return Http.post(url, data, "cms");
};
const apitradeGet = (url, data) => {
  return Http.get(url, data, "apitrade");
};

const apitradePost = (url, data) => {
  return Http.post(url, data, "apitrade");
};

const apiHoldingGet = (url, data) => {
  return Http.get(url, data, "apiholding");
};

const apiHoldingPost = (url, data) => {
  return Http.post(url, data, "apitrade");
};

const apiStockGet = (url, data) => {
  return Http.get(url, data, "apistock");
};

const apiStockPost = (url, data) => {
  return Http.post(url, data, "apistock");
};
//api5
export const fundList = (data) => {
  //基金列表
  return api5Get("fortune/fund_list", data);
};

export const fundNavHist = (data) => {
  //业绩走势
  return api5Get("fund/fund_navHist", data);
};

export const fundDetail = (data) => {
  //基金详情
  return api5Get("fund/detail", data);
};

export const historicalPerformance = (data) => {
  //历史表现
  return api5Get("fund/historical_performance", data);
};

export const fundPosition = (data) => {
  //十大持仓
  return api5Get("fund/position", data);
};

export const fundOptions = (data) => {
  //基金筛选
  return api5Get("fortune/fund_options", data);
};

export const agreeDisclaimer = (data) => {
  //同意基金协议
  return api5Get("fund/agree_disclaimer", data);
};

//apitrade
export const getRegionList = (data) => {
  return apitradeGet("user/login/get_region_list", data);
};

export const sendSms = (data) => {
  //获取验证码
  return apitradePost("user/login/send_sms", data);
};

export const verifySms = (data) => {
  //验证码登录
  return apitradePost("user/login/verify_sms", data);
};

export const getHelpCenterList = (data) => {
  //获取帮助中心
  return apitradeGet("user/index/help_center_list", data);
};

export const getSubList = (data) => {
  //获取帮助中心子元素
  return apitradeGet("app/help/sublist", data);
};

export const getSubDetail = (data) => {
  //获取帮助中心详情
  return apitradeGet("app/help/detail", data);
};

export const getSearch = (data) => {
  //搜索
  return apitradeGet("app/help/search", data);
};

//apiholding

export const holdingContactUs = (data) => {
  //新增联系我们
  return apiHoldingPost("/hk/contact_us", data);
};

//apistock
export const comexList = (data) => {
  return apiStockPost("quotes/stock/comexList", data);
};

export const contract = (data) => {
  return apiStockPost("quotes/stock/contract", data);
};

/**
 * 投资见解列表
 */
export const articleList = (data) => {
  return cmsPost("api/article/holdings/lists", data);
};
/**
 * 投资见解详情
 */
export const articleDetail = (data) => {
  return cmsPost("api/article/holdings/detail", data);
};

export const getIP = (data) => {
  //获取ip
  return rmsGet("api/ip/city/v2", data);
};

export const getAnnouncement = (data) => {
  //获取公告内容
  return apitradeGet("app/notice?page_size=20", data);
};
export const getNotice = (data) => {
  //获取公告内容
  return apitradeGet("app/notice?page_size=20&p=", data);
};

export const getImageCode = (data) => {
  //搜索
  return apitradePost("user/index/imageCode", data);
};
export const getSendSms = (data) => {
  //提交信息
  return apitradePost("hk/contact_us/send_sms", data);
};
export const getContactUs = (data) => {
  //提交信息
  return apitradePost("/hk/contact_us", data);
};

export const getAppointment = (data) => {
  //提交信息
  return w1Post("Api/hk/appointment_open", data);
};
export const postResetPwd = (data) => {
  const dataQs = {
    customContentType: "application/x-www-form-urlencoded",
    data: Qs.stringify(data)
   }
  return resetPwdPost(`/user/index/resetPwd?_t=${data._t}&_sign=${data._sign}`, dataQs);
};
export const getResetList = (data) => {

  return resetPwd("/user/login/get_region_list", data);
};
export const postMsgCodeServer = (data) => {
  const dataQs = {
    customContentType: "application/x-www-form-urlencoded",
    data: Qs.stringify(data)
   }

  return resetPwdPost(`/user/index/verify?_t=${data._t}&_sign=${data._sign}`, dataQs);
};
export const postImgCodeServer = (data) => {

  return resetPwdPost(`/user/index/imageCode?_t=${data._t}&_sign=${data._sign}`, data);
}
export const getTeacherDetailParams = (data) => {
  //获取老师的信息
  return apitradePost("/hk/official_salesperson/lists", data);
};

// export const getRegionList = (data) => {
//   //搜索
//   return apitradePost("/user/index/imageCode", data);
// };
// /user/login/get_region_list
