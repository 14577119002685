class Storage {
    // 写入数据到 localStorage
    static set(key, value) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  
    // 从 localStorage 获取数据
    static get(key) {
      const value = localStorage.getItem(key);
      return value ? JSON.parse(value) : null;
    }
  
    // 从 localStorage 删除数据
    static remove(key) {
      localStorage.removeItem(key);
    }
}

let isDev = process.env.NODE_ENV !== 'production';
const HAS_LOGIN = isDev ? "has_login_key_dev" : "has_login_key";
const TOKEN = isDev ? "token_key_dev" : "token_key";
const USER_INFO = isDev ? "user_info_obj_dev" : "user_info_obj";
const USER_LIST = isDev ? "user_list_obj_dev" : "user_list_obj"
export default {
  // 写入用户信息
  setUserInfo(val) {
    Storage.set(USER_INFO, val);
  },
  // 获取用户信息
  getUserInfo() {
    return Storage.get(USER_INFO);
  },
   // 写入用户列表
  setUserList(val) {
    Storage.set(USER_LIST, val);
  },
  // 获取用户列表
  getUserList() {
    return Storage.get(USER_LIST);
  },
  // 写入登录
  setHasLogin(val) {
    Storage.set(HAS_LOGIN, val);
  },
  // 获取是否登录
  getHasLogin() {
    return Storage.get(HAS_LOGIN);
  },
  // 写入accessToken
  setToken(val) {
    Storage.set(TOKEN, val);
  },
  // 获取accessToken
  getToken() {
    return Storage.get(TOKEN);
  },
  // 删除token
  removeToken() {
    Storage.remove(TOKEN);
  }
};
  